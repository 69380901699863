import React from "react"
import currency from "@data/pricing/currency.json"
import Icon from "@app-components/utils/icon"
import { OutboundLink } from "gatsby-plugin-google-analytics"

class Cost extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      status: "PENDING",
      cost: "",
      location: {},
      prices: {},
      mode: "2k",
      length: 0,
      price: 0,
      opacity: 1
    }
    this.onChange = this.onChange.bind(this)
    this.onCalculate = this.onCalculate.bind(this)
    this.reset = this.reset.bind(this)
    this.setPrice = this.setPrice.bind(this)
    this.setPending = this.setPending.bind(this)
  }
  async componentDidMount() {
    await Promise.all([this.getPrices(), this.getLocation()])
    this.setPending()
  }
  onChange({ target: { value } }) {
    this.setState(state => ({
      length: value < 0 || value > 1000 || Number.isNaN(value) ? state.length : value
    }))
  }
  onCalculate(e) {
    e.preventDefault()
    const prices = this.state.prices.reduce(
      (allPrices, price) => ({
        ...allPrices,
        [price.code]: price.prices.find(p => p.currency_code === currency[this.state.country].code).price
      }),
      {}
    )
    let price =
      parseFloat(prices.encoding_base_price) + parseFloat(this.state.length) * parseFloat(prices.encoding_per_minute)
    if (this.state.mode === "4k") price *= 2
    this.setPrice(parseFloat(price))
  }
  async getPrices() {
    const response = await fetch("https://api.cinesend.com/api/website/dcp")
    this.setState({ prices: await response.json() })
  }
  setPending() {
    this.setState({ status: "READY" })
  }
  async getLocation() {
    const response = await fetch("https://ipinfo.io/json")
    const location = await response.json()
    const country = ["US", "CA", "GB", "IN"].includes(location.country) ? location.country : "US"
    this.setState({ country })
  }
  setPrice(price) {
    this.setState(
      {
        opacity: 0
      },
      () => {
        setTimeout(() => {
          this.setState({
            opacity: 1,
            price
          })
        }, 500)
      }
    )
  }
  reset() {
    this.setPrice(0)
  }
  render() {
    return (
      <div id="dcp_cost_calculator" className="py5 px2">
        <div className="center mb3 max-width-5 mx-auto">
          <Icon className="inline-block mb1" size="64" icon="pricing-white" />
          <h2>DCP Pricing Calculator</h2>
          <p className="large-p-tag max-width-3 mx-auto mt2">
            Use the calculator below to see approximately how much a standard 2K or 4K DCP will cost to create in
            CineSend. Advanced formats will increase the price. It’s free to store your DCP in your account and share it
            electronically.
          </p>
        </div>
        <div
          style={{
            opacity: this.state.opacity,
            transition: "opacity .2s linear"
          }}>
          {this.state.price ? (
            <div className="center max-width-3 mx-auto">
              <strong style={{ fontSize: "22px" }} className="block red mb2">
                Your cost estimate
              </strong>
              <div
                style={{
                  boxShadow: "0 9px 25px 0 rgba(56,62,67,0.12)",
                  fontSize: "22px",
                  maxWidth: "300px"
                }}
                className="rounded p2 flex items-center  mx-auto justify-between mb1">
                <strong className="block">DCP</strong>
                <div className="pl3">
                  {currency[this.state.country].symbol}
                  {this.state.price.toFixed(2)}
                  &nbsp;
                  {currency[this.state.country].code}
                </div>
              </div>
              <small className="muted block">Pricing does not include applicable taxes</small>
              <div className="mt3">
                <p className="large-p-tag mb2">
                  Get started today on your CineSend account and order a DCP for your content.
                </p>
                <div className="flex items-center justify-center">
                  <button onClick={this.reset} className="link underline mr2">
                    <div className="regular">Reset Calculator</div>
                  </button>
                  <OutboundLink
                    href="https://app.cinesend.com/signup"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="ml2">
                    <button>Get Started</button>
                  </OutboundLink>
                </div>
              </div>
            </div>
          ) : (
            <div style={{ boxShadow: "0 9px 25px 0 rgba(56,62,67,0.12)" }} className="p3 rounded max-width-3 mx-auto">
              <form className="flex flex-wrap items-center">
                <div className="col-12 sm-col-3">
                  <label htmlFor="duration">Length of Movie (Minutes)</label>
                  <input
                    type="number"
                    id="duration"
                    min={0}
                    className="block col-12"
                    step={1}
                    max={1000}
                    disabled={this.state.status === "PENDING"}
                    value={this.state.length}
                    onChange={this.onChange}
                  />
                </div>
                <div className="flex items-center col-12 sm-col-6 justify-center py2 sm-py0 mt2">
                  {["2k", "4k"].map(res => (
                    <label key={res} className="flex items-center px2">
                      <input
                        type="radio"
                        key={res}
                        id={res}
                        className="mr1"
                        label={`${res} Resolution`}
                        checked={this.state.mode === res}
                        onChange={() => this.setState({ mode: res })}
                      />
                      {res} Resolution
                    </label>
                  ))}
                </div>
                <div className="col-12 sm-col-3 center sm-right-align mt2">
                  <button disabled={!this.state.length} onClick={this.onCalculate} type="submit">
                    Calculate Cost
                  </button>
                </div>
              </form>
            </div>
          )}
        </div>
      </div>
    )
  }
}

export default Cost
