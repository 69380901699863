import React from "react"
import PropTypes from "prop-types"
import Layout from "@app-components/layout/"
import Header from "@app-components/ui/header/top_bottom/"
import Feature from "@app-components/ui/feature/"
import Grid from "@app-components/ui/grid/"
import DCPCost from "@page-components/products/files/features/dcp-creation/cost/"
import HeaderImage from "@page-components/products/files/features/dcp-creation/media/header/"
import QualityImage from "@page-components/products/files/features/dcp-creation/media/quality/"
import FormatsImage from "@page-components/products/files/features/dcp-creation/media/formats/"
import HEADERS from "@data/headers/"

class DCP extends React.Component {
  componentDidMount() {
    if (this.props.location.hash === "#calculator") {
      setTimeout(() => {
        document.querySelector("#dcp_cost_calculator").scrollIntoView({ behavior: "smooth" })
      }, 15)
    }
  }
  render() {
    return (
      <Layout getStartedButtons={["demo", "get-started"]} page="features/dcp-creation">
        <Header
          title="Effortless DCP Creation"
          text="Easily mix and match audio, video, subtitle, and closed caption tracks to build a professional DCP in minutes."
          image={<HeaderImage />}
          secondaryNavigation={HEADERS.files}
        />
        <Feature
          title="Quality Guarantee"
          text="Don't take chances with a home-brew DCP. CineSend DCPs are created with Clipster, the mastering system trusted by major studios and Hollywood films. On top of that, a qualified technician will perform an end-to-end quality check in a DCI-compliant cinema."
          icon="check-white"
          image={<QualityImage />}
        />
        <Feature
          title="Advanced Formats"
          reverse
          text="CineSend can ingest and create both InterOp or SMPTE Digital Cinema Packages, and 3D, 4K, HFR, and all major immersive audio standards (Dolby ATMOS and Barco AuraMax and DTS:X)."
          icon="formats-white"
          image={<FormatsImage />}
        />
        <Grid
          title="The CineSend DCP Process"
          columnWidth={6}
          items={[
            {
              title: "1. Upload your film to CineSend",
              text:
                "Upload your final export as a ProRes, DPX image sequence, or any other common video file format. The better the file, the better the quality of your finished DCP.",
              _id: 1,
              icon: "upload-white"
            },
            {
              title: "2. Choose your options",
              text: `Launch the DCP creation tool, pick the video, audio, and optionally subtitles that you wish to use to create your DCP, and answer some basic questions.`,
              _id: 2,
              icon: "cart-white"
            },
            {
              title: "3. Encoding and QC",
              text: `CineSend will create your DCP. Once it’s done, a technician will quality check your film in an actual cinema with DCI-compliant projection equipment.`,
              _id: 3,
              icon: "convert-white"
            },
            {
              title: "4. Deliver your film",
              text:
                "Once approved by our QC technicians, the DCP will be available in your account to download, share, or ship to any cinema or film festival in the world.",
              _id: 4,
              icon: "send-white"
            }
          ]}
        />
        <DCPCost />
      </Layout>
    )
  }
}

DCP.propTypes = {
  location: PropTypes.object.isRequired
}

export default DCP
